import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-969385b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["element-loading-background", "element-loading-text"]
const _hoisted_2 = ["element-loading-background"]
const _hoisted_3 = { class: "RadarChartContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_parallel_coordinates = _resolveComponent("parallel-coordinates")!
  const _component_Tables = _resolveComponent("Tables")!
  const _component_radar_chart = _resolveComponent("radar-chart")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    id: "analytics",
    style: { marginTop: '3rem' },
    "element-loading-background": 'var(--color-background)',
    "element-loading-text": _ctx.$t('moderator.organism.analytics.loading')
  }, [
    _createElementVNode("div", {
      class: "AnalyticsParallelCoordinates",
      style: _normalizeStyle({
        opacity: _ctx.loadingSteps ? 0 : 1,
      })
    }, [
      (_ctx.axes.length > 0 && _ctx.dataEntries.length > 0 && !_ctx.loadingSteps)
        ? (_openBlock(), _createBlock(_component_parallel_coordinates, {
            key: 0,
            "chart-axes": 
          JSON.parse(JSON.stringify(_ctx.axes.filter((axis) => axis.available)))
        ,
            "participant-data": JSON.parse(JSON.stringify(_ctx.dataEntries)),
            steps: JSON.parse(JSON.stringify(_ctx.steps)),
            "selected-participant-id": _ctx.selectedParticipantId
          }, null, 8, ["chart-axes", "participant-data", "steps", "selected-participant-id"]))
        : _createCommentVNode("", true)
    ], 4),
    _createElementVNode("div", {
      class: "AnalyticsTables",
      "element-loading-background": 'var(--color-background)',
      style: _normalizeStyle({
        opacity: _ctx.loadingSteps ? 0 : 1,
      })
    }, [
      (_ctx.axes.length > 0 && _ctx.dataEntries.length > 0 && !_ctx.loadingSteps)
        ? (_openBlock(), _createBlock(_component_Tables, {
            key: 0,
            "participant-data": JSON.parse(JSON.stringify(_ctx.dataEntries)),
            axes: 
          JSON.parse(JSON.stringify(_ctx.axes.filter((axis) => axis.available)))
        ,
            onParticipantSelected: _ctx.participantSelectionChanged,
            style: _normalizeStyle({
          opacity: _ctx.loadingSteps ? 0 : 1,
        })
          }, null, 8, ["participant-data", "axes", "onParticipantSelected", "style"]))
        : _createCommentVNode("", true)
    ], 12, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.radarDataEntries, (entry, index) => {
        return (_openBlock(), _createBlock(_component_radar_chart, {
          key: 'radarChart' + index,
          labels: entry.labels,
          datasets: entry.data,
          size: 300,
          levels: 5,
          defaultColor: 'var(--color-dark-contrast-light)',
          "selected-participant-id": _ctx.selectedParticipantId
        }, null, 8, ["labels", "datasets", "defaultColor", "selected-participant-id"]))
      }), 128))
    ])
  ], 8, _hoisted_1)), [
    [_directive_loading, _ctx.loadingSteps]
  ])
}