import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35ea4bb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["points"]
const _hoisted_3 = ["x2", "y2"]
const _hoisted_4 = ["points", "fill", "fill-opacity", "stroke", "stroke-opacity"]
const _hoisted_5 = ["points", "fill", "fill-opacity", "stroke", "stroke-width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "radar-chart",
    style: _normalizeStyle({ width: _ctx.size + 'px', height: _ctx.size + 'px' })
  }, [
    (_openBlock(), _createElementBlock("svg", {
      width: _ctx.size,
      height: _ctx.size,
      viewBox: "0 0 100 100"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gridLevels, (level, index) => {
        return (_openBlock(), _createElementBlock("polygon", {
          key: 'grid-' + index,
          points: _ctx.getPolygonPoints(level),
          fill: "none",
          stroke: "#ccc",
          "stroke-width": "0.2"
        }, null, 8, _hoisted_2))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (label, index) => {
        return (_openBlock(), _createElementBlock("line", {
          key: 'axis-' + index,
          x1: "50",
          y1: "50",
          x2: _ctx.getAxisEnd(index, _ctx.maxRadius).x,
          y2: _ctx.getAxisEnd(index, _ctx.maxRadius).y,
          stroke: "#666",
          "stroke-width": "0.2"
        }, null, 8, _hoisted_3))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.normalizedDatasets, (dataset, datasetIndex) => {
        return (_openBlock(), _createElementBlock("polygon", {
          key: 'dataset-' + datasetIndex,
          points: _ctx.getDataPoints(dataset.data),
          fill: _ctx.getColor(dataset),
          "fill-opacity": _ctx.getOpacity(dataset),
          stroke: _ctx.getColor(dataset),
          "stroke-opacity": _ctx.getOpacity(dataset) + 0.2,
          "stroke-width": "0.5",
          class: "radarPolygon"
        }, null, 8, _hoisted_4))
      }), 128)),
      (_ctx.averageDataset)
        ? (_openBlock(), _createElementBlock("polygon", {
            key: 0,
            points: _ctx.getDataPoints(_ctx.averageDataset.data),
            fill: 'var(--color-evaluating)',
            "fill-opacity": _ctx.getOpacity(_ctx.averageDataset) + 0.1,
            stroke: 'var(--color-evaluating)',
            "stroke-width": _ctx.getOpacity(_ctx.averageDataset) + 0.2,
            class: "radarPolygon"
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true)
    ], 8, _hoisted_1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (label, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'label-' + index,
        class: "radar-label twoLineText",
        style: _normalizeStyle(_ctx.getLabelPosition(index))
      }, _toDisplayString(label), 5))
    }), 128))
  ], 4))
}